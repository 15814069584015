import { ElementRef, ViewContainerRef } from '@angular/core';

export const TOURISTS_MIN_ADULTS_COUNT = 1;
export const TOURISTS_MAX_ADULTS_COUNT = 10;
export const TOURISTS_MIN_CHILD_COUNT = 0;
export const TOURISTS_MAX_CHILD_COUNT = 4;

export const SEARCH_DATE_FORMAT = 'YYYY-MM-dd';

export interface SearchHotel {
  id: number;
  stars: string;
  category?: string | null;
  name: string;
  photo: string;
  region: string;
  salesCount: number;
  bookingLink?: string | null;
  bookingRating: number | null;
  bookingReviewsCount?: number | null;
  latitude?: number;
  longitude?: number;
  seaLine?: number;
  videoStories?: number[];
  beachType?: string;
  conversionOnlyAdults?: number | null;
  conversionWithChildren?: number | null;
  conversionPercent?: number;
}

export enum InitSearchRequestType {
  main = 0,
  forHotel = 3,
}

export interface SearchTouristsRecalc {
  adults?: number;
  childAges?: number[];
  splitRooms?: boolean;
}

export interface SearchTouristsRecalcModalConfig {
  viewRef?: ViewContainerRef;
  positionTop?: number;
  positionLeft?: number;
  anchorElement?: ElementRef;
}

export enum SearchTourSelectMode {
  drop = 'drop',
  calendar = 'calendar',
}

export enum CountryList {
  Turkey = 1,
  UAE = 2,
  Thailand = 3,
  Maldives = 9,
  Egypt = 18,
}
